
.custom-budget-content {
    padding: 40px 60px;
    height: 100%!important;
    /*display: flex;*/
    /*flex-direction: column;*/
    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .item {
        color: #333;
        font-size: 16px;
        margin-bottom: 10px;
        &.blue {
            background: #D7E8FF;
            padding: 10px 30px;
        }
    }
    .red {
        color: #FD4446;
    }
    .operate-box {
        text-align: right;
        margin-bottom: 60px;
        margin-top: 50px;
    }
}
.resource-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
