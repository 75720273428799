
.step-content {
    background: #fff;
    padding: 20px 30px 30px;
    margin-bottom: 20px;
}
.step-content-header {
    font-weight: 500;
    .big {
        font-size: 28px;
    }
}
.line-form {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-top: 50px;
    .line-left {
        width: 130px;
        margin-right: 20px;
        text-align: right;
        font-weight: 500;
    }
    .line-right {
        width: 1%;
        flex: 1;
        display: flex;
        align-items: center;
    }
}
.resource-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.prompt-icon {
    color: #D7D7D7;
    cursor: pointer;
    margin: 0 10px;
}
.directional-set-wrapper {
    width: 100%;
    padding: 20px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    .line-item {
        margin-bottom: 20px;
    }
    .directional-header-table {
        width: 100%;
        line-height: 50px;
        padding: 0 20px;
        background: #F5F5F5;
        box-sizing: border-box
    }
}
.red {
    color: #FD4446;
}
