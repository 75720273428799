
.custom-budget-content {
    padding: 40px 60px;
    height: 100%!important;
    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .item {
        color: #333;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .operate-box {
        .btn-red {
            position: absolute;
            right: 60px;
            bottom: 100px;
        }
    }
}
.customPanel {
    height: 250px;
    ::v-deep .el-cascader-menu {
        height: 100%;
        width: 50%;
        .el-cascader-menu__wrap {
            height: 100%;
            overflow-x: hidden;
        }
    }
}
